<template>
    <div id="modelDiv" style="width: 100%; height: 100%; display: flex;"></div>
    <div v-if="isShowLoading" style="position: absolute; top: calc( 50% - 10px ); left: calc( 50% - 100px ); width: 200px; height: 20px " id="msg">{{progressMsg}}</div>
</template>

<script>

    let windowHalfX = window.innerWidth / 2;
    let windowHalfY = window.innerHeight / 2;
    let scene, camera;
    let modelObj, ModelName;

    import * as THREE from 'three'
    import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
    import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
    //import { OBJLoader2 } from 'wwobjloader2';
    import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
    export default {
        name: "ModelViewer",
        data: function () {
            return {
                id: "",
                container: null,
                renderer: null,
                mouseX: 0,
                mouseY: 0,
                windowHalfX: window.innerWidth / 2,
                windowHalfY: window.innerHeight / 2,
                progressMsg: "",
                isShowLoading: false,
            }
        },
        mounted: function () {
            this.init();
        },
        methods: {
            init: function () {
                let _self = this;
                CzbdApp.Common.msg = this;
                this.id = $.fn.getUrlParam("id");
                let ModelName = null;
                $.fn.ajaxGet("/api/getmodelbyid?user=admin&id=" + this.id, {}, function (data) {
                    if (data.length>0)
                        ModelName = data[0].filepath;
                }, null, false);

                if (!ModelName) {
                    $.fn.showError("未找到对应的模型！");
                    return;
                }
                this.isShowForm = false;
                this.isShowLoading = true;
                this.container = document.getElementById("modelDiv");
                this.container.style.background = "url(./images/wt8.jpg) no-repeat";
                this.container.style.backgroundSize = "100% 100%";

                camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 3500);

                // scene

                scene = new THREE.Scene();

                const ambientLight = new THREE.AmbientLight(0xffffff, 1);
                scene.add(ambientLight);

                const pointLight = new THREE.SpotLight(0xffffff, 1);
                camera.add(pointLight);
                scene.add(camera);

                //// model
                let tempval = 0;

                function onProgress(xhr) {

                    if (xhr.lengthComputable) {
                        const percentComplete = xhr.loaded / xhr.total * 100;
                        if (tempval == percentComplete.toFixed(2))
                            return;
                        tempval = percentComplete.toFixed(2);
                        _self.progressMsg = 'model ' + tempval + '% downloaded';
                        if (percentComplete == 100) {
                            _self.isShowLoading = false;
                            return;
                        }

                    }

                }

                function onError(error) { window.console.log(error) }

                //let fName = ModelName.substr(ModelName.lastIndexOf('/') + 1);
                //let path = "/models/"+ModelName.substr(0,ModelName.lastIndexOf('/'));
                if (ModelName[0] !== '/')
                    ModelName = '/' + ModelName;
                let urlObj = "/models" + ModelName + ".obj";
                let urlMtl = "/models" + ModelName + ".mtl";

                var mtlLoader = new MTLLoader();
                mtlLoader.load(urlMtl, function (materials) {

                    materials.preload();
                    var objLoader = new OBJLoader();
                    objLoader.setMaterials(materials).load(urlObj, function (object) {
                       
                        //object.position.y = - 95;
                        //child.material.emissiveMap = child.material.map;
                        scene.add(object);
                        modelObj = object;

                        // fit camera to object
                        var bBox = new THREE.Box3().setFromObject(scene);

                        var height = bBox.max.y - bBox.min.y;
                        var dist = height / (2 * Math.tan(camera.fov * Math.PI / 360));
                        var pos = scene.position;

                        // fudge factor so the object doesn't take up the whole view
                        camera.position.set(pos.x, bBox.max.y - height / 5, dist * 1.5);
                        camera.lookAt(pos);

                    }, onProgress, onError);

                });


                /*

                const loader = new THREE.TextureLoader();
                // 加载一个资源
                loader.load(
                    // 资源URL
                    "/models/" + ModelName + ".jpg",
                    // onLoad回调
                    function (texture) {
                        // in this example we create the material when the texture is loaded
                        const material = new THREE.MeshBasicMaterial({
                            map: texture
                        });
                        console.log("material", material)
                        var objLoader = new OBJLoader();
                        //objLoader.setPath(path);
                        //objLoader.setMaterials(material);
                        objLoader.load(urlObj, function (object) {
                            console.log("geometry", object);
                            //object.position.y = - 95;
                            //child.material.emissiveMap = child.material.map;
                            object.traverse(function (child) {

                                if (child instanceof THREE.Mesh) {
                                    //here's how we add the texture, you may need to
                                    //UV Unwrap your model first in 3Ds
                                    child.material = new THREE.MeshBasicMaterial();
                                    child.material.map = texture;
                                }

                            });
                            scene.add(object);
                            modelObj = object;

                            // fit camera to object
                            var bBox = new THREE.Box3().setFromObject(scene);

                            var height = bBox.max.y - bBox.min.y;
                            var dist = height / (2 * Math.tan(camera.fov * Math.PI / 360));
                            var pos = scene.position;

                            // fudge factor so the object doesn't take up the whole view
                            camera.position.set(pos.x, bBox.max.y - height / 5, dist * 1.5);
                            camera.lookAt(pos);

                        }, onProgress, onError);
                    },
                    // 目前暂不支持onProgress的回调
                    undefined,
                    // onError回调
                    function (err) {
                        console.error('An error happened.');
                    }
                );
                  */


                this.renderer = new THREE.WebGLRenderer({
                    alpha: true
                });
                this.renderer.outputEncoding = THREE.sRGBEncoding;
                this.renderer.setPixelRatio(window.devicePixelRatio);
                this.renderer.setSize(window.innerWidth, window.innerHeight);
                this.container.appendChild(this.renderer.domElement);

                this.controls = new OrbitControls(camera, this.renderer.domElement);
                this.controls.listenToKeyEvents(window); // optional

                //controls.addEventListener( 'change', render ); // call this only in static scenes (i.e., if there is no animation loop)

                this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
                //controls.dampingFactor = 0.05;
                this.controls.autoRotation = true;
                //controls.screenSpacePanning = true;
                this.controls.minDistance = 3;
                this.controls.maxDistance = 3000;
                //controls.maxPolarAngle = Math.PI / 2;

                window.addEventListener('resize', this.onWindowResize);

                this.animate();
            },
            onWindowResize: function () {
                this.windowHalfX = window.innerWidth / 2;
                this.windowHalfY = window.innerHeight / 2;
                camera.aspect = window.innerWidth / window.innerHeight;
                camera.updateProjectionMatrix();
                this.renderer.setSize(window.innerWidth, window.innerHeight);
            },

            onDocumentMouseMove: function (event) {
                this.mouseX = (event.clientX - this.windowHalfX) / 2;
                this.mouseY = (event.clientY - this.windowHalfY) / 2;
            },

            animate: function () {
                requestAnimationFrame(this.animate);
                this.render();
            },

            render: function () {
                //if (modelObj)
                //    modelObj.rotateY(0.01);
                this.controls.update();
                this.renderer.render(scene, camera);

            }
        }
    }
</script>

<style scoped>
    body {
        background-color: #4859a7;
    }

    .button {
        display: inline-block;
        outline: none;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
        font: 16px/100% 'Microsoft yahei',Arial, Helvetica, sans-serif;
        padding: .5em 2em .55em;
        text-shadow: 0 1px 1px rgba(0,0,0,.3);
        -webkit-border-radius: .5em;
        -moz-border-radius: .5em;
        border-radius: .5em;
        -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.2);
        -moz-box-shadow: 0 1px 2px rgba(0,0,0,.2);
        box-shadow: 0 1px 2px rgba(0,0,0,.2);
    }

        .button:hover {
            text-decoration: none;
        }

        .button:active {
            position: relative;
            top: 1px;
        }

    .bigrounded {
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
    }

    .medium {
        font-size: 12px;
        padding: .4em 1.5em .42em;
    }

    .small {
        font-size: 11px;
        padding: .2em 1em .275em;
    }
    /* blue */
    .blue {
        color: #d9eef7;
        border: solid 1px #0076a3;
        background: #0095cd;
        background: -webkit-gradient(linear, left top, left bottom, from(#00adee), to(#0078a5));
        background: -moz-linear-gradient(top, #00adee, #0078a5);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#0078a5');
    }

        .blue:hover {
            background: #007ead;
            background: -webkit-gradient(linear, left top, left bottom, from(#0095cc), to(#00678e));
            background: -moz-linear-gradient(top, #0095cc, #00678e);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0095cc', endColorstr='#00678e');
        }

        .blue:active {
            color: #80bed6;
            background: -webkit-gradient(linear, left top, left bottom, from(#0078a5), to(#00adee));
            background: -moz-linear-gradient(top, #0078a5, #00adee);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0078a5', endColorstr='#00adee');
        }
    /* green */
    .green {
        color: #e8f0de;
        border: solid 1px #538312;
        background: #64991e;
        background: -webkit-gradient(linear, left top, left bottom, from(#7db72f), to(#4e7d0e));
        background: -moz-linear-gradient(top, #7db72f, #4e7d0e);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#7db72f', endColorstr='#4e7d0e');
    }

        .green:hover {
            background: #538018;
            background: -webkit-gradient(linear, left top, left bottom, from(#6b9d28), to(#436b0c));
            background: -moz-linear-gradient(top, #6b9d28, #436b0c);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6b9d28', endColorstr='#436b0c');
        }

        .green:active {
            color: #a9c08c;
            background: -webkit-gradient(linear, left top, left bottom, from(#4e7d0e), to(#7db72f));
            background: -moz-linear-gradient(top, #4e7d0e, #7db72f);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4e7d0e', endColorstr='#7db72f');
        }
    /* white */
    .white {
        color: #606060;
        border: solid 1px #b7b7b7;
        background: #fff;
        background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#ededed));
        background: -moz-linear-gradient(top, #fff, #ededed);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed');
    }

        .white:hover {
            background: #ededed;
            background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#dcdcdc));
            background: -moz-linear-gradient(top, #fff, #dcdcdc);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#dcdcdc');
        }

        .white:active {
            color: #999;
            background: -webkit-gradient(linear, left top, left bottom, from(#ededed), to(#fff));
            background: -moz-linear-gradient(top, #ededed, #fff);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#ffffff');
        }
    /* orange */
    .orange {
        color: #fef4e9;
        border: solid 1px #da7c0c;
        background: #f78d1d;
        background: -webkit-gradient(linear, left top, left bottom, from(#faa51a), to(#f47a20));
        background: -moz-linear-gradient(top, #faa51a, #f47a20);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#faa51a', endColorstr='#f47a20');
    }

        .orange:hover {
            background: #f47c20;
            background: -webkit-gradient(linear, left top, left bottom, from(#f88e11), to(#f06015));
            background: -moz-linear-gradient(top, #f88e11, #f06015);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f88e11', endColorstr='#f06015');
        }

        .orange:active {
            color: #fcd3a5;
            background: -webkit-gradient(linear, left top, left bottom, from(#f47a20), to(#faa51a));
            background: -moz-linear-gradient(top, #f47a20, #faa51a);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f47a20', endColorstr='#faa51a');
        }
</style>