<template>
    <div id="addForm" class="container">
        <h1>信息录入</h1>
        <el-form style="width:90%;" ref="form" :model="formData" label-position="top">
            <el-form-item label="手机号" prop="phonenumber">
                <el-input v-model="formData.phonenumber" placeholder="请输入手机号" v-on:change="getUserName"></el-input>
            </el-form-item>
            <el-form-item label="用户名" prop="username">
                <el-input v-model="formData.username" placeholder="请输入用户名"></el-input>
                <span class="warnning">{{nameAlert}}</span>
            </el-form-item>

            <el-form-item label="目录名称（可空）" prop="directory">
                <el-input v-model="formData.directory" placeholder="请输入内容"></el-input>
            </el-form-item>     
            
            <el-form-item label="描述（可空）" prop="modeldesc">
                <el-input v-model="formData.modeldesc" placeholder="请输入内容"></el-input>
            </el-form-item>

            <el-form-item label="提取码（可自行更改）" prop="code">
                <el-input v-model="formData.code" placeholder="请输入内容"></el-input>
            </el-form-item>
            <div style="display:flex;flex-direction:row">
                <el-form-item label="是否在首页" prop="ishome" style="margin: 0px 10px;">
                    <el-checkbox v-model="formData.ishome">是否在首页</el-checkbox>
                </el-form-item>
                <el-form-item label="是否产品宣传页" prop="iscreation" style="margin: 0px 50px;">
                    <el-checkbox v-model="formData.iscreation">是否产品宣传页</el-checkbox>
                </el-form-item>
            </div>
            <el-form-item label="模型文件" prop="files">
                <el-upload drag ref="upload" action="none" :auto-upload="false" :on-change="checkFile" multiple style="width:100%">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <div class="el-upload__tip" slot="tip">可一次性将三个模型文件拖拽到此处</div>
                </el-upload>
            </el-form-item>
            <el-button style="width:100%;margin-top:10px;" type="success" v-on:click="submit">提交</el-button>
            <el-button style="width:100%;margin-top:10px;margin-left:0px;" type="primary" v-on:click="show">管理</el-button>

        </el-form>
        <el-dialog title="提交中，请稍后……" v-model="isShowLoading" center style="width:80%;height:150px; background-color:#ffffffff;" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
            <el-progress :text-inside="true" :stroke-width="26" :percentage="progressPercent" style="width:100%;"></el-progress>
        </el-dialog>
    </div>
</template>

<script>
    //$.fn.ajaxGet("/login" + this.formData.code, {}, function (data) {
    //    if (!data)
    //        window.location.href = "/login";
    //}, null, false);

    import axios from "axios";
    export default {
        name: "Upload",
        data: function () {
            return {
                isShowLoading: false,
                progressPercent: 0,
                formData: {
                    directory: "",
                    code: "",
                    phonenumber: "",
                    username: "",
                    modeldesc: "",
                    ishome: false,
                    iscreation:false
                },
                fileList: [],
                nameAlert:""
            }
        },
        mounted: function () {
            CzbdApp.Common.msg = this;
        },
        methods: {
            checkFile: function (file, fileList) {
                this.fileList = fileList;
            },
            show: function () {
                location.href = "/manage";
            },
            getUserName: function () {
               
                let _self = this;
                _self.nameAlert = "";
                _self.formData.username = "";
                $.fn.ajaxGet("/api/getuserinfo", { phone: this.formData.phonenumber }, function (data) {
                    if (data.length == 1) {
                        _self.formData.username = data[0].username;
                    } else if (data.length > 1) {
                        $.fn.showError("该手机号在数据库中存在重复记录，请联系管理员处理。");
                    } else if (data.length == 0) {
                        _self.nameAlert="该手机没有录入，请手动填写用户名自动注册。"
                    } 
                    
                }, null, false);

            },
            submit: function () {
                let _self = this;
                if (this.formData.code.length == 0) {
                    alert("请输入提取码！");
                    return;
                }
                let filename = "";
                if (this.fileList.length > 0) {
                    filename = this.fileList[0].name.substring(0, this.fileList[0].name.lastIndexOf('.'));
                } else {
                    $.fn.showError("未选择上传的模型，操作取消。");
                    return;
                }

                let ModelName="";
                $.fn.ajaxGet("/api/getmodel/" + this.formData.code, {}, function (data) {
                    if (data) {
                        data = JSON.parse(data);
                        ModelName = data.filepath;
                    }
                }, function (error) { console.log(error); }, false);
                let checkResult;
                $.fn.ajaxGet("/api/checkpath", { code: this.formData.code, dir: this.formData.directory, filename: filename }, function (data) {
                        checkResult = data;
                }, function (error) { console.log(error); }, false);
                console.log(checkResult)
                if (checkResult.Status == '0') {
                    $.fn.showError(checkResult.ReturnData);
                    return;
                }

                if (ModelName&&ModelName.length>0) {
                    $.fn.showError("提取码已存在，请修改！")
                    return;
                }
                let fData = new FormData();
                for (let k in this.formData) {
                    fData.append(k, this.formData[k]);
                }
                this.fileList.forEach(function (f) {
                    fData.append("file", f.raw);
                    fData.append("fileName", f.name.substring(0, f.name.lastIndexOf('.')));
                })
                this.isShowLoading = true;
                axios({
                    url: CzbdApp.Common.RootUrl + "/api/save",
                    method: 'post',
                    data: fData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                    responseType: 'text',
                    onUploadProgress: (progressEvent) => {
                        // progressEvent.loaded:已上传文件大小
                        // progressEvent.total:被上传文件的总大小
                        // 进度条
                        _self.progressPercent = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2) | 0;
                    }
                }).then(res => {
                    $.fn.showSuccess(res.data);
                    _self.$refs.form.resetFields();
                    _self.fileList = [];
                    _self.$refs.upload.clearFiles();
                    _self.isShowLoading = false;
                    _self.progressPercent = 0;
                }, err => {
                    _self.isShowLoading = false;
                    $.fn.showError(err.message);
                }).catch(err => {
                    _self.isShowLoading = false;
                    $.fn.showError(err.response.data);
                });
            }
        }
    }
</script>

<style scoped>
    h1 {
        font-weight: 700;
        font-size: 28px;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }
</style>
<style>

    .el-form-item__label {
        color: #ffffff;
    }

    .el-upload-list__item-name {
        color: greenyellow;
    }

    .warnning{
        color:red;
        font-style:italic;
    }
</style>