<template>
    
    <div class="container" >
        <div style="height:100px;display:flex;justify-content:center;align-items:center;flex-direction:column;">
            <div style="font-family:Arial; font-size:22px;font-weight:700; margin-bottom:10px">Metanix数字人展示平台</div>
            <div style="font-family: Arial; font-size: 14px; font-weight: 700;">Metanix Digital Human Stage</div>
        </div>
        <div  style="width:90%; max-width: 300px; ">
            <el-form ref="form" :model="formData" label-position="top">
                <el-form-item label="手机号" prop="userName">
                    <el-input v-model="formData.userName" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input type="password" v-model="formData.password" placeholder="请输入密码"></el-input>
                </el-form-item>
                
                    <el-button-group style="width:100%;text-align:right">
                        <el-button style="width:50%" type="success" v-on:click="submit">提交</el-button>
                        <el-button style="width:50%" type="primary" v-on:click="cancle">取消</el-button>
                    </el-button-group>
                
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Login",
        data: function () {
            return {
                formData: {
                    userName: "",
                    password:""
                }
            }
        },
        mounted: function () {
            CzbdApp.Common.msg = this;
        },
       methods: {
           submit: function () {
               if (this.formData.userName.length === 0 || this.formData.password.length===0) {
                   $.fn.showError("用户名、密码不能为空！");
                   return;
               }
               $.fn.postToApi("/login", this.formData, function (data) {
                   if (data.Status === '1')
                       window.location.href = "/uploadmodel";
               });
           },
           cancle: function () {
               this.$refs.form.resetFields();
           }
        }
    }
</script>

<style scoped>
    h1 {
        margin:20px;
        font-weight: 700;
        font-size: 28px;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }
</style>