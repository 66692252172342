<template>
    <div id="addForm" class="container">
        <h1>信息录入</h1>
        <el-form style="width:90%;" ref="form" :model="formData" label-position="top">
            <el-form-item label="手机号" prop="phonenumber">
                <el-input v-model="formData.phonenumber" placeholder="请输入手机号" v-on:change="getUserName"></el-input>
            </el-form-item>
            <el-form-item label="用户名" prop="username">
                <el-input v-model="formData.username" placeholder="请输入用户名"></el-input>
                <span class="warnning">{{nameAlert}}</span>
            </el-form-item>

            <el-form-item label="描述（可空）" prop="modeldesc">
                <el-input v-model="formData.modeldesc" placeholder="请输入内容"></el-input>
            </el-form-item>

            <el-form-item label="提取码（可自行更改）" prop="code">
                <el-input v-model="formData.code" placeholder="请输入内容"></el-input>
            </el-form-item>
            <div style="display:flex;flex-direction:row">
                <el-form-item label="是否在首页" prop="ishome" style="margin: 0px 10px;">
                    <el-checkbox v-model="formData.ishome">是否在首页</el-checkbox>
                </el-form-item>
                <el-form-item label="是否产品宣传页" prop="iscreation" style="margin: 0px 50px;">
                    <el-checkbox v-model="formData.iscreation">是否产品宣传页</el-checkbox>
                </el-form-item>
            </div>

            <el-button style="width:100%;margin-top:10px;" type="success" v-on:click="submit">提交</el-button>
            <el-button style="width:100%;margin-top:10px;margin-left:0px;" type="primary" v-on:click="show">管理</el-button>

        </el-form>
    </div>
</template>

<script>
    //$.fn.ajaxGet("/login" + this.formData.code, {}, function (data) {
    //    if (!data)
    //        window.location.href = "/login";
    //}, null, false);

    import axios from "axios";
    export default {
        name: "Upload",
        data: function () {
            return {
                formData: {
                    id:"",
                    directory: "",
                    code: "",
                    phonenumber: "",
                    username: "",
                    modeldesc: "",
                    ishome: false,
                    iscreation: false
                },
                nameAlert: ""
            }
        },
        mounted: function () {
            CzbdApp.Common.msg = this;
            this.init();
        },
        methods: {
            init: function () {
                let _self = this;
                this.formData.id = $.fn.getUrlParam("id");
                $.fn.ajaxGet("/api/getmodelbyid?user=admin&id=" + this.formData.id, {}, function (data) {
                    if (data.length > 0) {
                        _self.formData.id = data[0].id;
                        _self.formData.code = data[0].accesscode;
                        _self.formData.phonenumber = data[0].phonenumber;
                        _self.formData.username = data[0].username;
                        _self.formData.modeldesc = data[0].modeldesc;
                        _self.formData.ishome = data[0].ishome;
                        _self.formData.iscreation = data[0].iscreation;
                    }
                }, null, false);
            },
            checkFile: function (file, fileList) {
                this.fileList = fileList;
            },
            show: function () {
                location.href = "/manage";
            },
            getUserName: function () {
                let _self = this;
                _self.nameAlert = "";
                _self.formData.username = "";
                $.fn.ajaxGet("/api/getuserinfo", { phone: this.formData.phonenumber }, function (data) {
                    if (data.length == 1) {
                        _self.formData.username = data[0].username;
                    } else if (data.length > 1) {
                        $.fn.showError("该手机号在数据库中存在重复记录，请联系管理员处理。");
                    } else if (data.length == 0) {
                        _self.nameAlert = "该手机没有录入，请手动填写用户名自动注册。"
                    }

                }, null, false);

            },
            submit: function () {
                let _self = this;
                if (this.formData.code.length == 0) {
                    alert("请输入提取码！");
                    return;
                }
                let ModelName="";
                $.fn.ajaxGet("/api/getmodel/" + this.formData.code, {}, function (data) {
                    data = JSON.parse(data);
                    console.log("data", data);
                    if (data && data.id != _self.formData.id) {
                        $.fn.showError("提取码已存在，请修改！")
                        return;
                    }
                }, null, false);
                $.fn.postToApi("/api/update", this.formData, function (data) {
                    location.href = "/manage";
                });
            }
        }
    }
</script>

<style scoped>
    h1 {
        font-weight: 700;
        font-size: 28px;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }
</style>
<style>

    .el-form-item__label {
        color: #ffffff;
    }

    .el-upload-list__item-name {
        color: greenyellow;
    }

    .warnning {
        color: red;
        font-style: italic;
    }
</style>