<template>
    <div class="listcontainer">
        <div class="left">
            <el-form ref="searchForm" :model="searchData" label-position="top">
                <el-form-item label="手机号" prop="phonenumber">
                    <el-input v-model="searchData.phonenumber" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="searchData.username" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="路径" prop="filepath">
                    <el-input v-model="searchData.filepath" placeholder="请输入路径"></el-input>
                </el-form-item>

            </el-form>
            <div style="margin:5px 0px">
                <el-button type="success" v-on:click="submit">查询</el-button>
                <el-button type="primary" v-on:click="cancle">重置</el-button>
            </div>
            <div>
                <el-button type="primary" v-on:click="add">新增</el-button>
            </div>
        </div>
        <div class="right">
            <el-pagination :total="totalcount" :page-sizes="pagesizes" :page-size="pagesize" :layout="pagelayout" size="small" v-on:current-change="pageChange" :current-page="pageno" v-on:size-change="pageSizeChange"></el-pagination>

            <table class="tab" >
                <tr>
                    <th>用户</th>
                    <th>手机号</th>
                    <th>描述</th>
                    <th>制作日期</th>
                    <th>存储路径</th>
                    <th>提取码</th>
                    <th>首页</th>
                    <th>展示页</th>
                    <th>操作</th>
                </tr>
                <tr v-for="row in tabdata">
                    <td >{{row.username}}</td>
                    <td>{{row.phonenumber}}</td>
                    <td>{{row.modeldesc}}</td>
                    <td>{{row.createdate}}</td>
                    <td>{{row.filepath}}</td>
                    <td>{{row.accesscode}}</td>
                    <td><el-switch :value="row.ishome" active-text="是" inactive-text="否" ></el-switch></td>
                    <td><el-switch :value="row.iscreation" active-text="是" inactive-text="否"></el-switch></td>
                    <td>
                        <el-button size="mini" type="text" v-on:click="showDetail(row.id)">查看</el-button>
                        <el-button size="mini" type="text" v-on:click="update(row.id)">修改</el-button>
                        <el-button size="mini" type="text" v-on:click="deletemodel(row)">删除</el-button>
                    </td>
                </tr>
            </table>
            <el-pagination :total="totalcount" :page-sizes="pagesizes" :page-size="pagesize" :layout="pagelayout" size="small" v-on:current-change="pageChange" :current-page="pageno" v-on:size-change="pageSizeChange"></el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                searchData: {
                    username: "",
                    phonenumber: "",
                    filepath:""
                },
                tabdata: [],
                totalcount: 0,
                pagesize: 20,
                pageno: 1,
                sorter: { key: "createdate", order: "desc" },
                pagesizes: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                pagelayout:"total, sizes, prev, pager, next, jumper"
            }
        },
        mounted: function () {
            CzbdApp.Common.msg = this;
            this.loaddata(this.pageno, this.pagesize, this.sorter.key, this.sorter.order, this.searchData);
        },
        methods: {
            submit: function () {
                this.loaddata(this.pageno, this.pagesize, this.sorter.key, this.sorter.order, this.searchData);
            },
            cancle: function () {
                this.$refs.searchForm.resetFields();
                this.loaddata(this.pageno, this.pagesize, this.sorter.key, this.sorter.order, this.searchData);
            },
            add: function () {
                location.href = "/uploadmodel";
            },
            showDetail: function (id) {
                window.open("/modelviewer?id=" + id);
            },
            update: function (id) {
                location.href="/editmodel?id=" + id;
            },
            updatehome: function (para) {
                console.log("updatehome",para)  
            },
            deletemodel: function (row) {
                if (!confirm("确定删除记录吗？")) {
                    return;
                }
                let _self = this;
                $.fn.postToApi("/api/deletemodel", { user: "admin", modelInfo: row }, function (ret) {
                    _self.loaddata(_self.pageno, _self.pagesize, _self.sorter.key, _self.sorter.order, _self.searchData);
                });
            },
            pageChange: function (_pageno) {
                this.pageno = _pageno;
                this.loaddata(this.pageno, this.pagesize, this.sorter.key, this.sorter.order, this.searchData);
            },
            pageSizeChange: function (_pagesize) {
                this.pagesize = _pagesize;
                this.loaddata(this.pageno, this.pagesize, this.sorter.key, this.sorter.order, this.searchData);
            },
            onSort: function (para) {
                if (para.order == "normal")
                    return;
                this.sorter = para;
                this.loaddata(this.pageno, this.pagesize, para.key, para.order, this.searchData);
            },
            onSelectChange: function (selection) {
                this.selectedDatas = selection;
            },
            sortchange: function (obj) {
                if (obj.order === 'descending')
                    this.sorter.order = "desc";
                else
                    this.sorter.order = "";
                this.sorter.key = obj.prop;

                this.loaddata(this.pageno, this.pagesize, this.sorter.key, this.sorter.order, this.searchData);
            },
            loaddata: function (pageno, pagesize, sortfield, descstr, conditionData) {
                let _self = this;
                $.fn.getDataFromApi("/api/getModelList", { pageno: pageno, pagesize: pagesize, sortfield: sortfield, descstr: descstr, conditionData: JSON.stringify(conditionData) },
                    function (data) {
                        console.log("tabdata",data)
                        _self.tabdata = data.listdata;
                        _self.totalcount = data.totalcount;
                    });

            },
        }
    }
</script>

<style scoped>
    .listcontainer{
        display:flex;
        flex-direction:row;
        position:relative;
        width:100%;
        height:100%;
    }
    .left{
        width:200px;
        vertical-align:middle;
        border-right-width:1px;
        border-right-color:aliceblue;
        border-right-style:dotted;
        padding:10px;
    }
    .right{
        flex:1;
        padding:10px;
    }
    .tab {
        border-collapse: collapse;
        margin:10px 0px;
        width:100%;
    }
        .tab th {
            background-color: steelblue;
        }
        .tab td, .tab th {
            border: 1px solid #ddd;
            text-align:center;
        }
        .tab tr:nth-child(2n+1) {
            background-color: #666666;
        }
        .tab tr:hover {
            background-color:darkslateblue;
        }
</style>