import { createRouter, createWebHistory } from "vue-router";
import HumanViewer from "@/components/HumanViewer.vue";
import Upload from "@/components/Upload.vue";
import Login from "@/components/Login.vue";
import ModelList from "@/components/ModelList.vue";
import ModelViewerVue from "../components/ModelViewer.vue";
import EditModel from "../components/EditModel.vue";

const router = createRouter({
    history:createWebHistory(),
    routes: [
        { path: '/login', component: Login },
        { path: '/uploadmodel', component: Upload },
        { path: '/', component: HumanViewer },
        { path: '/manage', component: ModelList },
        { path: '/modelviewer', component: ModelViewerVue },
        { path: '/editmodel', component: EditModel },
    ]
});
export default router;
