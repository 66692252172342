<template>
    <div v-if="isShowForm" id="codeform" class="main-div">
        <!--<div style="height:100px;display:flex;justify-content:center;align-items:center;flex-direction:column;">
            <div style="font-family:Arial; font-size:22px;font-weight:700; margin-bottom:10px">Metanix数字人展示平台</div>
            <div style="font-family: Arial; font-size: 14px; font-weight: 700;">Virtual Digital Human Stage</div>
        </div>
        <div style="background: url(images/logo1.jpg) no-repeat; background-position: center center ;height:450px;width:100%; "></div>
        <div style="   height:100px;display:flex;flex-direction:column;align-items:center;justify-content:start">-->
            <!--<img src="images/logo1.png" width="100" style="margin-bottom:20px" />-->
            <!--<div><label style="font-family: Arial; font-size: 18px; font-weight: 700; ">密码:</label><input v-model="code" style="font-family: Arial; font-size: 18px; font-weight: 700; width: 60px; margin-bottom: 10px; margin-left: 10px;" /></div>
            <div><button class="button green medium" v-on:click="init" style="font-family: Arial; font-size: 18px; font-weight: 700; ">查看</button></div>
        </div>-->
        <div>
            <div>
            <label style=" font-size: 20px;letter-spacing:5px;font-family:'Microsoft YaHei' ;font-weight:600;">密码:</label>
            <input v-model="code" class="input-code" />
                <button class="button black" v-on:click="init" style="font-family: Arial; font-size: 18px; margin-left: 10px; opacity:0.7">查看</button>
            </div>
            
        </div>
    </div>
    <div id="modelDiv" style="width: 100%; height: 100%; display: none;"></div>
    <div v-if="isShowLoading" style="position: absolute; top: calc( 50% - 10px ); left: calc( 50% - 100px ); width: 200px; height: 20px " id="msg">{{progressMsg}}</div>
</template>

<script>

    let windowHalfX = window.innerWidth / 2;
    let windowHalfY = window.innerHeight / 2;
    let scene, camera;
    let modelObj, ModelName;

    import * as THREE from 'three'
    import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
    import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
    //import { OBJLoader2 } from 'wwobjloader2';
    import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
    export default {
        name: "HumanViewer",
        data: function () {
            return {
                code: "",
                container: null,
                renderer: null,
                mouseX: 0,
                mouseY: 0,
                windowHalfX: window.innerWidth / 2,
                windowHalfY: window.innerHeight / 2,
                progressMsg: "",
                isShowLoading: false,
                isShowForm: true
            }
        },
        methods: {
            init: function () {
                let _self = this;
                CzbdApp.Common.msg = this;
                if (!this.code || this.code.length === 0) {
                    $.fn.showError("请输入提取码！")
                    return;
                }

                let ModelName = "";
                $.fn.ajaxGet("/api/getmodel/" + this.code, {}, function (data) {
                    if (data) {
                        data = JSON.parse(data);
                        ModelName = data.filepath;
                    }
                }, null, false);
                if (ModelName&&ModelName.length==0) {
                    $.fn.showError("提取码输入错误，未找到对应的模型！")
                    return;
                }
                this.isShowForm = false;
                this.isShowLoading = true;
                this.container = document.getElementById("modelDiv");
                this.container.style.background = "url(./images/wt8.jpg) no-repeat";
                this.container.style.backgroundSize = "100% 100%";
                this.container.style.display = "flex";

                camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 3500);

                // scene

                scene = new THREE.Scene();

                const ambientLight = new THREE.AmbientLight(0xffffff, 1);
                scene.add(ambientLight);

                const pointLight = new THREE.SpotLight(0xffffff, 1);
                camera.add(pointLight);
                scene.add(camera);

                //// model
                let tempval = 0;

                function onProgress(xhr) {

                    if (xhr.lengthComputable) {
                        const percentComplete = xhr.loaded / xhr.total * 100;
                        if (tempval == percentComplete.toFixed(2))
                            return;
                        tempval = percentComplete.toFixed(2);
                        _self.progressMsg = 'model ' + tempval + '% downloaded';
                        if (percentComplete == 100) {
                            _self.isShowLoading = false;
                            return;
                        }

                    }

                }

                function onError(error) { window.console.log(error) }

                //let fName = ModelName.substr(ModelName.lastIndexOf('/') + 1);
                //let path = "/models/"+ModelName.substr(0,ModelName.lastIndexOf('/'));

                if (ModelName[0] !== '/')
                    ModelName = '/' + ModelName;
                let urlObj = "/models" + ModelName + ".obj";
                let urlMtl = "/models" + ModelName + ".mtl";

                var mtlLoader = new MTLLoader();
                mtlLoader.load(urlMtl, function (materials) {

                    materials.preload();
                    var objLoader = new OBJLoader();
                    objLoader.setMaterials(materials).load(urlObj, function (object) {
                       
                        //object.position.y = - 95;
                        //child.material.emissiveMap = child.material.map;
                        scene.add(object);
                        modelObj = object;

                        // fit camera to object
                        var bBox = new THREE.Box3().setFromObject(scene);

                        var height = bBox.max.y - bBox.min.y;
                        var dist = height / (2 * Math.tan(camera.fov * Math.PI / 360));
                        var pos = scene.position;

                        // fudge factor so the object doesn't take up the whole view
                        camera.position.set(pos.x, bBox.max.y - height / 5, dist * 1.5);
                        camera.lookAt(pos);

                    }, onProgress, onError);

                });


                /*

                const loader = new THREE.TextureLoader();
                // 加载一个资源
                loader.load(
                    // 资源URL
                    "/models/" + ModelName + ".jpg",
                    // onLoad回调
                    function (texture) {
                        // in this example we create the material when the texture is loaded
                        const material = new THREE.MeshBasicMaterial({
                            map: texture
                        });
                        console.log("material", material)
                        var objLoader = new OBJLoader();
                        //objLoader.setPath(path);
                        //objLoader.setMaterials(material);
                        objLoader.load(urlObj, function (object) {
                            console.log("geometry", object);
                            //object.position.y = - 95;
                            //child.material.emissiveMap = child.material.map;
                            object.traverse(function (child) {

                                if (child instanceof THREE.Mesh) {
                                    //here's how we add the texture, you may need to
                                    //UV Unwrap your model first in 3Ds
                                    child.material = new THREE.MeshBasicMaterial();
                                    child.material.map = texture;
                                }

                            });
                            scene.add(object);
                            modelObj = object;

                            // fit camera to object
                            var bBox = new THREE.Box3().setFromObject(scene);

                            var height = bBox.max.y - bBox.min.y;
                            var dist = height / (2 * Math.tan(camera.fov * Math.PI / 360));
                            var pos = scene.position;

                            // fudge factor so the object doesn't take up the whole view
                            camera.position.set(pos.x, bBox.max.y - height / 5, dist * 1.5);
                            camera.lookAt(pos);

                        }, onProgress, onError);
                    },
                    // 目前暂不支持onProgress的回调
                    undefined,
                    // onError回调
                    function (err) {
                        console.error('An error happened.');
                    }
                );
                  */


                this.renderer = new THREE.WebGLRenderer({
                    alpha: true
                });
                this.renderer.outputEncoding = THREE.sRGBEncoding;
                this.renderer.setPixelRatio(window.devicePixelRatio);
                this.renderer.setSize(window.innerWidth, window.innerHeight);
                this.container.appendChild(this.renderer.domElement);

                this.controls = new OrbitControls(camera, this.renderer.domElement);
                this.controls.listenToKeyEvents(window); // optional

                //controls.addEventListener( 'change', render ); // call this only in static scenes (i.e., if there is no animation loop)

                this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
                //controls.dampingFactor = 0.05;
                this.controls.autoRotation = true;
                //controls.screenSpacePanning = true;
                this.controls.minDistance = 3;
                this.controls.maxDistance = 3000;
                //controls.maxPolarAngle = Math.PI / 2;

                window.addEventListener('resize', this.onWindowResize);

                this.animate();
            },
            onWindowResize: function () {
                this.windowHalfX = window.innerWidth / 2;
                this.windowHalfY = window.innerHeight / 2;
                camera.aspect = window.innerWidth / window.innerHeight;
                camera.updateProjectionMatrix();
                this.renderer.setSize(window.innerWidth, window.innerHeight);
            },

            onDocumentMouseMove: function (event) {
                this.mouseX = (event.clientX - this.windowHalfX) / 2;
                this.mouseY = (event.clientY - this.windowHalfY) / 2;
            },

            animate: function () {
                requestAnimationFrame(this.animate);
                this.render();
            },

            render: function () {
                //if (modelObj)
                //    modelObj.rotateY(0.01);
                this.controls.update();
                this.renderer.render(scene, camera);

            }
        }
    }
</script>

<style scoped>
    body {
        background-color: #000206;
    }

    .input-code {
        font-family: Arial;
        font-size: 18px;
        font-weight: 700;
        width: 60px;
        margin-bottom: 10px;
        margin-left: 10px;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 2px solid #ffffff;
        background-color: transparent;
        color: #ffffff;
        width: 100px;
    }

    .main-div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #000206;
        background-image:url(../../public/images/home2.png);
        background-position:center center ;
        background-repeat:no-repeat;
        background-size:contain;
    }
    .button {
        width: 70px;
        
        text-align: center;
        font-weight: bold;
        color: #fff;
        border-radius: 5px;
        position: relative;
        overflow: hidden;
    }

        .button:nth-child(6n) {
            margin-right: 0;
        }

        .button.black {
            background: #000206;
        }


</style>